import { cilDinner, cilMoney } from "@coreui/icons";
import { CCol, CFormGroup, CInputGroup, CLabel } from "@coreui/react";
import React from "react";
import { Control, FieldArray, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "../../../../components/Select/Select";
import { Product } from "../../../../interfaces/entities/Product";
import { NewOrderFormData, ProductFormData } from "./interfaces";

interface ProductInputsProps {
  productFields: FieldArray<NewOrderFormData, "products">[];
  products: Product[];
  watchProducts: ProductFormData[];
  register: UseFormRegister<NewOrderFormData>;
  control: Control<NewOrderFormData>;
}

const ProductInputs: React.FC<ProductInputsProps> = ({
  productFields,
  products,
  watchProducts,
  control,
  register,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {productFields.map((item, index) => {
        const priceOptions =
          products
            .find((p) => p.id === watchProducts[index].id)
            ?.prices.map((p) => ({ label: p, value: p })) || [];

        return (
          <CFormGroup row key={item.id}>
            <Select
              icon={cilDinner}
              options={products.map((p) => ({
                label: p.name,
                value: p.id,
              }))}
              label={t("product")}
              control={control}
              name={`products.${index}.id`}
            />
            <CCol md="4">
              <Select
                icon={cilMoney}
                options={priceOptions}
                label={t("price")}
                control={control}
                name={`products.${index}.price`}
              />
            </CCol>
            <CCol md="2">
              <CLabel>{t("quantity")}</CLabel>
              <CInputGroup>
                <input
                  type="number"
                  min={0}
                  className={`form-control`}
                  {...register(`products.${index}.quantity`, {
                    valueAsNumber: true,
                  })}
                />
              </CInputGroup>
            </CCol>
          </CFormGroup>
        );
      })}
    </React.Fragment>
  );
};

export default ProductInputs;
