import React from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CForm,
  CPagination,
  CRow,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import { Order } from "../../../interfaces/entities/Order";
import { formatDate } from "../../../utils/date";
import useSession from "../../../hooks/useSession/useSession";
import useChildrenSales from "./useChildrenSales";
import Select from "../../../components/Select/Select";
import { months, years } from "../../../constants/monthsAndYears";
import { AccessLevel } from "../../../interfaces/globals/session";
import UserSelector from "../../../components/UserSelector/UserSelector";

const ChildrenSales: React.FC = () => {
  const { session } = useSession();
  const {
    childrenOrders,
    tableColumns,
    getPersonalOrders,
    pagination,
    control,
    setPeriod,
  } = useChildrenSales();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <CRow className="mb-3 ">
        <CCol>
          <h1>{t("Children sales List")}</h1>
        </CCol>
        <CForm inline className="w-100">
          {session?.accessLevel === AccessLevel.ADMIN && (
            <CCol xs="5">
              <UserSelector
                inline
                label={t("user")}
                control={control}
                name="userId"
              />
            </CCol>
          )}
          <Select
            control={control}
            inline
            name="period.month"
            label={t("month")}
            hideSelectedOptions
            options={months.map((month) => ({
              label: t(month.label),
              value: month.value,
            }))}
            handleOnChange={(v) => {
              setPeriod((p) => ({
                ...p,
                month: Number(v),
              }));
            }}
          />
          <Select
            control={control}
            inline
            name="period.year"
            label={t("year")}
            hideSelectedOptions
            options={years}
            handleOnChange={(v) => {
              setPeriod((p) => ({
                ...p,
                year: Number(v),
              }));
            }}
          />
        </CForm>
      </CRow>
      <CRow>
        <CCol>
          <CCard>
            <CCardBody>
              <CDataTable
                items={childrenOrders}
                fields={tableColumns}
                scopedSlots={{
                  user: (item: Order) => <td>{item.user.name}</td>,
                  id: (item: Order) => <td>{item.user.id}</td>,
                  product: (item: Order) => (
                    <td>
                      {item.products.map((product) => (
                        <p>
                          {product.quantity} - {product.name}
                        </p>
                      ))}
                    </td>
                  ),
                  createdAt: (item: Order) => (
                    <td>{formatDate(new Date(item.createdAt))}</td>
                  ),
                }}
                itemsPerPage={10}
              />
              <CRow>
                <CCol className="d-flex justify-content-center">
                  <CPagination
                    activePage={pagination.page}
                    pages={Math.ceil(pagination.totalRows / pagination.limit)}
                    onActivePageChange={(newPage: number) =>
                      getPersonalOrders(newPage || 1)
                    }
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </React.Fragment>
  );
};

export default ChildrenSales;
