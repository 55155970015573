import React from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import useSession from "../hooks/useSession/useSession";
import { AccessLevel } from "../interfaces/globals/session";
import paths from "./paths";

interface ProtectedRouteProps extends RouteProps {
  accessLevels: AccessLevel[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { session } = useSession();
  const location = useLocation();

  if (session) {
    if (props.accessLevels.includes(session.accessLevel)) {
      return (
        <Layout>
          <Route {...props} />
        </Layout>
      );
    }

    return (
      <Redirect
        to={{
          pathname: paths.login,
        }}
      />
    );
  }

  return (
    <Redirect
      to={{
        pathname: paths.login,
        search: `?redirect=${location.pathname}`,
      }}
    />
  );
};

export default ProtectedRoute;
