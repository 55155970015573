import { CModal, CModalBody } from "@coreui/react";
import React from "react";
import Receipt from "../Receipt/Receipt";

export interface ReceiptModalProps {
  onClose: () => void;
  id?: number;
}

const ReceiptModal: React.FC<ReceiptModalProps> = ({ onClose, id }) => {
  return (
    <CModal show={!!id} onClose={onClose} size="lg">
      <CModalBody className="m-5">{id && <Receipt id={id} />}</CModalBody>
    </CModal>
  );
};

export default ReceiptModal;
