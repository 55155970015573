import {
  cibMailRu,
  cilBuilding,
  cilCalendar,
  cilCreditCard,
  cilFlagAlt,
  cilHouse,
  cilPhone,
  cilScreenSmartphone,
  cilUser,
} from "@coreui/icons";
import { CButton, CCol, CForm, CFormGroup, CRow } from "@coreui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DatePicker from "../../../components/DatePicker/DatePicker";
import Select from "../../../components/Select/Select";
import TextInput from "../../../components/TextInput/TextInput";
import UserSelector from "../../../components/UserSelector/UserSelector";
import { required } from "../../../utils/validations/rhfMessages";
import { CreateUserFormData } from "./interfaces";

interface NewDistributorFormProps {
  onSubmit: (data: CreateUserFormData) => void;
}

const NewDistributorForm: React.FC<NewDistributorFormProps> = ({
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateUserFormData>();
  const { t } = useTranslation();

  const contries = [
    { value: "DO", label: t("do") },
    { value: "US", label: t("us") },
  ];

  return (
    <React.Fragment>
      <h2 className="mb-4">{t("New Distributor")}</h2>
      <CForm onSubmit={handleSubmit(onSubmit)}>
        <CFormGroup row>
          <TextInput
            label={t("First name")}
            icon={cilUser}
            register={register("firstName", required(t))}
            error={errors.firstName?.message}
          />

          <TextInput
            label={t("Last name")}
            icon={cilUser}
            register={register("lastName", required(t))}
            error={errors.lastName?.message}
          />
        </CFormGroup>

        <CFormGroup row>
          <DatePicker
            label={t("Date of Birth")}
            icon={cilCalendar}
            {...register("dateOfBirth")}
          />
          <TextInput
            label={t("dni")}
            icon={cilCreditCard}
            register={register("dni", required(t))}
            error={errors.dni?.message}
          />
        </CFormGroup>

        <CFormGroup row>
          <TextInput
            label="Email"
            icon={cibMailRu}
            register={register("email")}
          />
        </CFormGroup>

        <CFormGroup row>
          <TextInput
            label={t("address")}
            icon={cilHouse}
            register={register("address")}
          />
        </CFormGroup>

        <CFormGroup row>
          <Select
            icon={cilFlagAlt}
            options={contries}
            label={t("country")}
            control={control}
            name="country"
          />
          <Select
            icon={cilBuilding}
            options={contries}
            label={t("state")}
            control={control}
            name="state"
          />

          <TextInput
            label={t("city")}
            icon={cilBuilding}
            register={register("city")}
          />
        </CFormGroup>

        <CFormGroup row>
          <TextInput
            label={t("phone number")}
            icon={cilPhone}
            register={register("phone")}
          />

          <TextInput
            label={t("cellphone number")}
            icon={cilScreenSmartphone}
            register={register("cellphone")}
          />
        </CFormGroup>

        <CFormGroup row>
          <UserSelector
            hasIcon
            label={t("sponsor")}
            name="sponsor"
            control={control}
          />
        </CFormGroup>
        <CRow>
          <CCol className="d-flex justify-content-end">
            <CButton color="success" size="lg" type="submit">
              Continuar
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </React.Fragment>
  );
};

export default NewDistributorForm;
