import React from "react";
import { CButton, CCol, CForm, CFormGroup, CRow } from "@coreui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import StemvidaService from "../../api/StemvidaService";
import TextInput from "../../components/TextInput/TextInput";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router";
import paths from "../../routes/paths";
import useSession from "../../hooks/useSession/useSession";
import { AccessLevel } from "../../interfaces/globals/session";

interface ProfileForm {
  password: string;
  confirmPassword: string;
}

const NewPasswordForm: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<ProfileForm>();
  const { t } = useTranslation();
  const passwordValue = watch("password");
  const MySwal = withReactContent(Swal);
  const history = useHistory();
  const { session } = useSession();

  const onSubmit = async (data: ProfileForm) => {
    await StemvidaService.changePassword(data.password);
    await MySwal.fire({
      title: <p>{t("Password saved successfully")}</p>,
      icon: "success",
    });

    const homePath =
      session?.accessLevel === AccessLevel.ADMIN
        ? paths.orders
        : paths.commisions;

    history.push(homePath);
  };

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      <CRow>
        <CCol sm={3}>
          <CFormGroup row>
            <TextInput
              label={t("New Password")}
              register={register("password", { required: true })}
              type="password"
              error={errors.password?.message}
            />
          </CFormGroup>
          <CFormGroup row>
            <TextInput
              label={t("Confirm new password")}
              type="password"
              register={register("confirmPassword", {
                validate: (value) =>
                  value === passwordValue ||
                  (t("The passwords do not match") as string),
                required: true,
              })}
              error={errors.confirmPassword?.message}
            />
          </CFormGroup>
          <CRow>
            <CCol xs="6">
              <CButton color="primary" className="px-4" type="submit">
                {t("Send")}
              </CButton>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CForm>
  );
};

export default NewPasswordForm;
