import React from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilPlaylistAdd } from "@coreui/icons";
import CreateOrderModal from "../CreateOrder/CreateOrderModal";
import ReceiptModal from "./ReceiptModal";
import useOrderList from "./useOrderList";
import { useTranslation } from "react-i18next";
import { Order } from "../../../interfaces/entities/Order";
import { calculateTotalOrder } from "../../../utils/orders";
import { formatDate } from "../../../utils/date";
import TextInput from "../../../components/TextInput/TextInput";

const OrderList: React.FC = () => {
  const {
    handleCloseReceipt,
    handlePageChange,
    handleShowReceipt,
    onNewOrderFormClose,
    orders,
    receipt,
    showModal,
    handleNewOrderClick,
    pagination,
    tableColumns,
    register,
  } = useOrderList();

  const { t } = useTranslation();

  if (!orders) {
    return (
      <CSpinner color="primary" style={{ width: "4rem", height: "4rem" }} />
    );
  }

  return (
    <React.Fragment>
      <CRow className="mb-3 ">
        <CCol>
          <h1>{t("Orders List")}</h1>
        </CCol>
        <CCol className="d-flex justify-content-end">
          <CButton size="lg" color="success" onClick={handleNewOrderClick}>
            <CIcon content={cilPlaylistAdd} />
            {t("New Order")}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CCard>
            <CCardBody>
              <div className="mb-3">
                <TextInput
                  register={register("filter")}
                  debounceTime={500}
                  placeholder={t("Search by Order number or distributor")}
                />
              </div>
              <CCol>
                <CDataTable
                  items={orders}
                  fields={tableColumns}
                  scopedSlots={{
                    user: (item: Order) => <td>{item.user.name}</td>,
                    seller: (item: Order) => (
                      <td>
                        {item.seller?.name ||
                          process.env.REACT_APP_DEFAULT_SELLER_LABEL}
                      </td>
                    ),
                    total: (item: Order) => (
                      <td>{calculateTotalOrder(item)}</td>
                    ),
                    createdAt: (item: Order) => (
                      <td>{formatDate(item.createdAt)}</td>
                    ),
                    actions: (item: Order) => (
                      <td>
                        <CButton
                          size="lg"
                          color="success"
                          onClick={() => handleShowReceipt(item.orderId)}
                        >
                          {t("See receipt")}
                        </CButton>
                      </td>
                    ),
                  }}
                  itemsPerPage={5}
                />
              </CCol>
              <CRow>
                <CCol className="d-flex justify-content-center">
                  <CPagination
                    activePage={pagination.page}
                    pages={Math.ceil(pagination.totalRows / pagination.limit)}
                    onActivePageChange={handlePageChange}
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CreateOrderModal show={showModal} onClose={onNewOrderFormClose} />
      <ReceiptModal onClose={handleCloseReceipt} id={receipt} />
    </React.Fragment>
  );
};

export default OrderList;
