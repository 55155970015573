import React from "react";
import CIcon from "@coreui/icons-react";
import {
  CCol,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CLabel,
} from "@coreui/react";
import { UseFormRegisterReturn } from "react-hook-form";

export interface DatePickerProps extends UseFormRegisterReturn {
  label?: string;
  icon: any;
}

const DatePicker: React.FC<DatePickerProps> = ({ icon, label, ...rest }) => {
  return (
    <CCol>
      <CLabel>{label}</CLabel>
      <CInputGroup>
        <CInputGroupAppend>
          <CInputGroupText>
            <CIcon content={icon} />
          </CInputGroupText>
        </CInputGroupAppend>
        <input type="date" className="form-control" {...rest} />
      </CInputGroup>
    </CCol>
  );
};

export default DatePicker;
