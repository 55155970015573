export enum PaymentMethods {
  cash = "cash",
  card = "card",
  wallet = "wallet",
  transaction = "transaction",
}

export interface paymentMethodFormData {
  method: string;
  transactionId?: string;
  amount: number;
}

export interface ProductFormData {
  id: string;
  price: number;
  quantity: number;
}

export interface NewOrderFormData {
  userId: string;
  products: ProductFormData[];
  paymentMethod: paymentMethodFormData[];
  discount?: number;
  shipping?: number;
}
