import { CCol, CInput, CRow } from "@coreui/react";
import React from "react";
import { FieldArray, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  NewOrderFormData,
  paymentMethodFormData,
  PaymentMethods,
} from "./interfaces";

interface PaymentMethodInputsProps {
  watchPaymentMethod: paymentMethodFormData[];
  register: UseFormRegister<NewOrderFormData>;
  selectedPaymentMethods: FieldArray<NewOrderFormData, "paymentMethod">[];
}

const PaymentMethodInputs: React.FC<PaymentMethodInputsProps> = ({
  watchPaymentMethod,
  selectedPaymentMethods,
  register,
}) => {
  const { t } = useTranslation();

  const renderInput = (paymentMethod: PaymentMethods) => {
    const m = selectedPaymentMethods.find(
      (value) => value.method === paymentMethod
    );

    if (!m) {
      return;
    }

    if (
      (m.method === PaymentMethods.cash ||
        m.method === PaymentMethods.wallet) &&
      selectedPaymentMethods.length < 2
    ) {
      return;
    }

    return (
      <CRow className="mb-3">
        <CCol md="2">
          <p>{t(paymentMethod)}</p>
        </CCol>
        {watchPaymentMethod?.length >= 2 && (
          <CCol md="2">
            <input
              className="form-control"
              placeholder={t("amount")}
              min={0}
              type="number"
              {...register(
                `paymentMethod.${selectedPaymentMethods.findIndex(
                  (m) => m.method === paymentMethod
                )}.amount`,
                {
                  valueAsNumber: true,
                  required: true,
                }
              )}
            />
          </CCol>
        )}
        {(paymentMethod === PaymentMethods.transaction ||
          paymentMethod === PaymentMethods.card) && (
          <CCol md="5">
            <CInput
              placeholder={t("Transaction number")}
              {...register(
                `paymentMethod.${selectedPaymentMethods.findIndex(
                  (m) => m.method === paymentMethod
                )}.transactionId`
              )}
            />
          </CCol>
        )}
      </CRow>
    );
  };

  return (
    <React.Fragment>
      {Object.keys(PaymentMethods).map((paymentMethod) =>
        renderInput(paymentMethod as PaymentMethods)
      )}
    </React.Fragment>
  );
};
export default PaymentMethodInputs;
