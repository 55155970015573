import React, { useEffect, useState } from "react";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";
import Logo from "./../../assets/images/logo.png";

import CIcon from "@coreui/icons-react";
import {
  cilCart,
  cilChartLine,
  cilDollar,
  cilGroup,
  cilLan,
} from "@coreui/icons";
import paths from "../../routes/paths";
import useSession from "../../hooks/useSession/useSession";
import { AccessLevel } from "../../interfaces/globals/session";
import { useTranslation } from "react-i18next";

interface SidebarProps {
  showSidebar: boolean;
  setShowSidebar: (val: any) => void;
}

interface NavigationItem {
  _tag: string;
  name: string;
  to?: string;
  icon: JSX.Element;
  _children?: {
    _tag: string;
    name: string;
    to: string;
  }[];
}

const Sidebar: React.FC<SidebarProps> = ({ showSidebar, setShowSidebar }) => {
  const { session } = useSession();
  const { t } = useTranslation();
  const [adminNavigation, setAdminNavigation] = useState<NavigationItem[]>([]);
  const [userNavigation, setUserNavigation] = useState<NavigationItem[]>([]);

  useEffect(() => {
    setAdminNavigation([
      {
        _tag: "CSidebarNavItem",
        name: t("Orders"),
        to: paths.orders,
        icon: <CIcon content={cilCart} customClasses="c-sidebar-nav-icon" />,
      },
      {
        _tag: "CSidebarNavItem",
        name: t("Commissions"),
        to: paths.commisions,
        icon: <CIcon content={cilDollar} customClasses="c-sidebar-nav-icon" />,
      },
      {
        _tag: "CSidebarNavItem",
        name: t("Users"),
        to: paths.users,
        icon: <CIcon content={cilGroup} customClasses="c-sidebar-nav-icon" />,
      },
      {
        _tag: "CSidebarNavDropdown",
        name: t("Reports"),
        icon: (
          <CIcon content={cilChartLine} customClasses="c-sidebar-nav-icon" />
        ),
        _children: [
          {
            _tag: "CSidebarNavItem",
            name: t("Commissions"),
            to: paths.commisionsReports,
          },
          {
            _tag: "CSidebarNavItem",
            name: t("Payments"),
            to: paths.paymentsReports,
          },
        ],
      },

      {
        _tag: "CSidebarNavDropdown",
        name: t("Sales"),
        icon: (
          <CIcon content={cilChartLine} customClasses="c-sidebar-nav-icon" />
        ),
        _children: [
          {
            _tag: "CSidebarNavItem",
            name: t("Personal"),
            to: paths.personalSales,
          },
          {
            _tag: "CSidebarNavItem",
            name: t("Line"),
            to: paths.childrenSales,
          },
        ],
      },
      {
        _tag: "CSidebarNavDropdown",
        name: t("Lines"),
        icon: <CIcon content={cilLan} customClasses="c-sidebar-nav-icon" />,
        _children: [
          {
            _tag: "CSidebarNavItem",
            name: t("Volume per line"),
            to: paths.volumePerLine,
          },
          {
            _tag: "CSidebarNavItem",
            name: t("My lines"),
            to: paths.line,
          },
        ],
      },
    ]);

    setUserNavigation([
      {
        _tag: "CSidebarNavDropdown",
        name: t("Sales"),
        icon: (
          <CIcon content={cilChartLine} customClasses="c-sidebar-nav-icon" />
        ),
        _children: [
          {
            _tag: "CSidebarNavItem",
            name: t("Personal"),
            to: paths.personalSales,
          },
          {
            _tag: "CSidebarNavItem",
            name: t("Line"),
            to: paths.childrenSales,
          },
        ],
      },
      {
        _tag: "CSidebarNavDropdown",
        name: t("Line"),
        icon: <CIcon content={cilLan} customClasses="c-sidebar-nav-icon" />,
        _children: [
          {
            _tag: "CSidebarNavItem",
            name: t("Volume per line"),
            to: paths.volumePerLine,
          },
          {
            _tag: "CSidebarNavItem",
            name: t("My line"),
            to: paths.line,
          },
        ],
      },
    ]);
  }, [t]);

  return (
    <CSidebar show={showSidebar} onShowChange={setShowSidebar}>
      <CSidebarBrand
        className="d-md-down-none"
        to="/"
        style={{
          backgroundColor: "white",
        }}
      >
        <img
          src={Logo}
          alt="Stem Global Connexions RD"
          style={{
            height: 56,
          }}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={
            session?.accessLevel === AccessLevel.ADMIN
              ? adminNavigation
              : userNavigation
          }
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default Sidebar;
