import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormGroup,
  CRow,
} from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Select from "../../../components/Select/Select";
import { months, years } from "../../../constants/monthsAndYears";
import usePrint from "../../../hooks/useSession/usePrint";
import useCommissionsReport from "./useCommissionsReport";

const CommisionsReport: React.FC = () => {
  const { t } = useTranslation();
  const {
    control,
    setPeriod,
    availableCommisions,
    total,
    unavailableCommissions,
    closeMonth,
    isClosingMonth,
    isClosed,
  } = useCommissionsReport();
  const MySwal = withReactContent(Swal);
  const { componentRef, handlePrint } = usePrint({
    documentTitle: "factura",
  });

  const onCloseMonthClick = async () => {
    const result = await MySwal.fire({
      title: "Está seguro que desea cerrar el mes?",
      showDenyButton: true,
      confirmButtonText: "Cerrar",
      denyButtonText: `Cancelar`,
    });

    if (result.isConfirmed) {
      await closeMonth();
    } else if (result.isDenied) {
      Swal.fire("No se cerró el mes");
    }
  };

  return (
    <React.Fragment>
      <CRow className="mb-3 ">
        <CCol>
          <h1>{t("Monthly Commissions")}</h1>
        </CCol>
        <CCol className="d-flex justify-content-end">
          <CButton
            size="lg"
            color="warning"
            onClick={onCloseMonthClick}
            className="mr-3"
            disabled={isClosingMonth || isClosed}
          >
            {t("Cerrar mes")}
          </CButton>
          <CButton size="lg" color="success" onClick={handlePrint}>
            {t("Print")}
          </CButton>
        </CCol>
      </CRow>
      {isClosingMonth && (
        <CAlert color="danger">
          Cerrando mes, esto podria durar unos minutos
        </CAlert>
      )}
      <CRow>
        <CCol>
          <CCard>
            <CCardBody>
              <CRow className="mb-3">
                <CCol xs="5">
                  <CForm>
                    <CFormGroup row>
                      <Select
                        control={control}
                        inline
                        name="month"
                        label={t("month")}
                        hideSelectedOptions
                        options={months.map((month) => ({
                          label: t(month.label),
                          value: month.value,
                        }))}
                        handleOnChange={(v) => {
                          setPeriod((p) => ({
                            ...p,
                            month: Number(v),
                          }));
                        }}
                      />
                      <Select
                        control={control}
                        inline
                        name="year"
                        label={t("year")}
                        hideSelectedOptions
                        options={years}
                        handleOnChange={(v) => {
                          setPeriod((p) => ({
                            ...p,
                            year: Number(v),
                          }));
                        }}
                      />
                    </CFormGroup>
                  </CForm>
                </CCol>
              </CRow>
              <div ref={componentRef}>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{t("user ID")}</th>
                      <th scope="col">{t("Name")}</th>
                      <th scope="col">{t("Commission")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {availableCommisions.map((commision, key) => (
                      <tr key={key}>
                        <td>{commision.userId}</td>
                        <td>{commision.name}</td>
                        <td>{commision.amount}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={2} className="font-weight-bold">
                        Total
                      </td>
                      <td colSpan={3}>{total}</td>
                    </tr>
                    <tr>
                      <td colSpan={3} />
                    </tr>
                    {unavailableCommissions.map((commision, key) => (
                      <tr key={key}>
                        <td>{commision.userId}</td>
                        <td>{commision.name}</td>
                        <td>{commision.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </React.Fragment>
  );
};

export default CommisionsReport;
