export interface Session {
  id: string;
  name: string;
  accessLevel: AccessLevel;
}

export enum AccessLevel {
  ADMIN = "ADMIN",
  USER = "USER",
}
