import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, generatePath } from "react-router-dom";
import StemvidaService from "../../../api/StemvidaService";
import paths from "../../../routes/paths";
import { UserList } from "./interfaces";
import { GetUsersList } from "./../../../interfaces/payloads";

interface UserListForm {
  filter: string;
}

const useUserList = () => {
  const { t } = useTranslation();
  const { register } = useForm<UserListForm>();
  const [filter, setFilter] = useState<string>("");

  const [users, setUsers] = useState<UserList[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalRows: 1,
  });
  const history = useHistory();

  const getUserList = useCallback(
    async (newPage: number = 1) => {
      const filterParams: GetUsersList = {
        page: newPage,
        limit: pagination.limit,
      };
      if (filter !== "") {
        filterParams.filter = filter;
      }
      const response = await StemvidaService.getUserList(filterParams);

      setUsers(response.data);
      setPagination((cp) => ({
        ...cp,
        page: newPage,
        totalRows: response.total,
      }));
    },
    [filter, pagination.limit]
  );

  const handlePageChange = (newPage: number) => {
    getUserList(newPage);
  };

  const tableColumns = [
    {
      key: "id",
      label: t("ID"),
    },
    {
      key: "name",
      label: t("user"),
    },
    {
      key: "actions",
      label: "",
    },
  ];

  const onRowClick = (id: string) => {
    history.push(
      generatePath(paths.userDetails, {
        id,
      })
    );
  };

  useEffect(() => {
    getUserList();
  }, [getUserList]);

  const onFilterChange = (text: string) => {
    setFilter(text);
  };

  return {
    handlePageChange,
    pagination,
    tableColumns,
    users,
    onRowClick,
    register,
    onFilterChange,
  };
};

export default useUserList;
