import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import paths from "../../routes/paths";
import { Session } from "../../interfaces/globals/session";
import { sessionInfoCookie } from "./constants";
import StemvidaService from "../../api/StemvidaService";

const useSession = () => {
  const sessionString = localStorage.getItem(sessionInfoCookie);
  const history = useHistory();

  let session: Session | null = null;

  if (sessionString) {
    const parseao = JSON.parse(sessionString);
    session = parseao;
  }

  const logout = async () => {
    await StemvidaService.logout();
    Cookies.remove(sessionInfoCookie);
    history.push(paths.login);
  };

  return { session, logout };
};

export default useSession;
