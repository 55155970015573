import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OrderListContext from "../../../contexts/OrderListContext";

const useOrderList = () => {
  const [showModal, setShowModal] = useState(false);
  const [receipt, setReceipt] = useState<number>();
  const { t } = useTranslation();
  const { getOrders, orders, handlePageChange, pagination, register } =
    useContext(OrderListContext);

  const tableColumns = [
    {
      key: "orderId",
      label: t("orderId"),
    },
    {
      key: "createdAt",
      label: t("orderDate"),
    },
    {
      key: "user",
      label: t("distributor"),
    },
    {
      key: "total",
      label: "Total",
    },
    {
      key: "seller",
      label: t("seller"),
    },
    {
      key: "actions",
      label: t("actions"),
    },
  ];

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  const onNewOrderFormClose = () => {
    setShowModal(false);
  };

  const handleShowReceipt = (id: number) => {
    setReceipt(id);
  };

  const handleCloseReceipt = () => {
    setReceipt(undefined);
  };

  const handleNewOrderClick = () => {
    setShowModal(true);
  };

  return {
    onNewOrderFormClose,
    handleCloseReceipt,
    handlePageChange,
    handleShowReceipt,
    handleNewOrderClick,
    pagination,
    showModal,
    receipt,
    tableColumns,
    orders,
    register,
  };
};

export default useOrderList;
