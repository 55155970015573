import React, { createContext, useCallback, useState } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import StemvidaService from "../api/StemvidaService";
import { Order } from "../interfaces/entities/Order";
import { GetOrders } from "../interfaces/payloads";

interface OrderListFormData {
  filter: string;
}

interface OrderListProps {
  orders: Order[];
  getOrders: (newPage?: number) => Promise<void>;
  handlePageChange: (newPage: number) => void;
  register: UseFormRegister<OrderListFormData>;
  pagination: { page: number; limit: number; totalRows: number };
}

const OrderListsInitialState: OrderListProps = {
  orders: [],
  getOrders: async () => {},
  register: {} as UseFormRegister<OrderListFormData>,
  handlePageChange: () => {},
  pagination: {
    page: 1,
    limit: 10,
    totalRows: 1,
  },
};

const OrderListsContext = createContext<OrderListProps>(OrderListsInitialState);
export default OrderListsContext;

export const OrderListProvider: React.FC = (props) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [pagination, setPagination] = useState(
    OrderListsInitialState.pagination
  );
  const { register, watch } = useForm<OrderListFormData>();
  const watchFilter = watch("filter");

  const getOrders = useCallback(
    async (newPage: number = 1) => {
      const orderPayload: GetOrders = {
        page: newPage,
        limit: 10,
      };

      if (watchFilter) {
        orderPayload.searchBy = watchFilter;
      }

      const response = await StemvidaService.getOrders(orderPayload);

      setOrders(response.data);
      setPagination((cp) => ({
        ...cp,
        page: newPage,
        totalRows: response.total,
      }));
    },
    [watchFilter]
  );

  const handlePageChange = (newPage: number) => {
    getOrders(newPage);
  };

  return (
    <OrderListsContext.Provider
      value={{
        orders,
        getOrders,
        handlePageChange,
        pagination,
        register,
      }}
    >
      {props.children}
    </OrderListsContext.Provider>
  );
};
