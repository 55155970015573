import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import StemvidaService from "../../../api/StemvidaService";
import useSession from "../../../hooks/useSession/useSession";
import useTablePagination from "../../../hooks/useSession/useTablePagination";
import { Order } from "../../../interfaces/entities/Order";
import { MonthAndYear } from "../../../interfaces/globals/DateRange";
import { AccessLevel } from "../../../interfaces/globals/session";

interface SalesForm {
  userId: string;
  period: MonthAndYear;
}

const useChildrenSales = () => {
  const { session } = useSession();
  const { t } = useTranslation();
  const [childrenOrders, setChildrenOrders] = useState<Order[]>();
  const itemsPerPage = 10;
  const { changePage, pagination } = useTablePagination(itemsPerPage);
  const today = new Date();
  const initialPeriod = {
    month: today.getMonth() + 1,
    year: today.getFullYear(),
  };
  const { control, watch } = useForm<SalesForm>({
    defaultValues: {
      period: initialPeriod,
    },
  });
  const userId =
    session?.accessLevel === AccessLevel.ADMIN ? watch("userId") : session?.id;
  const [period, setPeriod] = useState<MonthAndYear>(initialPeriod);

  const tableColumns = [
    {
      key: "orderId",
      label: t("orderId"),
    },
    {
      key: "id",
      label: "Id",
    },
    {
      key: "user",
      label: t("user"),
    },
    {
      key: "product",
      label: t("Products"),
    },
    {
      key: "createdAt",
      label: t("orderDate"),
    },
  ];

  const getPersonalOrders = useCallback(
    async (page: number = 1) => {
      if (userId) {
        const response = await StemvidaService.getChildrenOrders(
          userId,
          {
            page,
            itemsPerPage,
          },
          period
        );
        setChildrenOrders(response.orders);
        changePage(page, response.total);
      }
    },
    [changePage, period, userId]
  );

  useEffect(() => {
    getPersonalOrders();
  }, [getPersonalOrders]);

  return {
    changePage,
    pagination,
    tableColumns,
    childrenOrders,
    control,
    setPeriod,
    getPersonalOrders,
  };
};

export default useChildrenSales;
