import React from "react";
import { useTranslation } from "react-i18next";
import NewPasswordForm from "./NewPasswordForm";

const Profile: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t("Profile")}</h1>
      <NewPasswordForm />
    </div>
  );
};

export default Profile;
