import { CCard, CCardBody, CCol, CForm, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import StemvidaService from "../../../api/StemvidaService";
import UserSelector from "../../../components/UserSelector/UserSelector";
import useSession from "../../../hooks/useSession/useSession";
import { MonthAndYear } from "../../../interfaces/globals/DateRange";
import { AccessLevel } from "../../../interfaces/globals/session";
import OrganizationChart from "@dabeng/react-orgchart";
import { UserWithChildren } from "../../../interfaces/entities/User";

interface LinesForm {
  userId: string;
  period: MonthAndYear;
}

interface NodeData extends UserWithChildren {
  relationship?: string;
  areChildrenOpen?: boolean;
}

interface NodeProps {
  nodeData: NodeData;
  handleChildrenClick: (id: string) => void;
  canDisplayChildren: boolean;
}

const Node: React.FC<NodeProps> = ({
  nodeData,
  handleChildrenClick,
  canDisplayChildren,
}) => {
  return (
    <div>
      <p className="position">{nodeData.id}</p>
      <p className="fullname">{nodeData.name}</p>
      {canDisplayChildren && (
        <div
          className="see-children-buttons"
          onClick={(e) => {
            e.stopPropagation();
            handleChildrenClick(nodeData.id);
          }}
        >
          <p className="fullname">
            {nodeData.areChildrenOpen
              ? "Ocultar descendientes"
              : "Ver descendientes"}
          </p>
        </div>
      )}
    </div>
  );
};

const Line: React.FC = () => {
  const today = new Date();
  const initialPeriod = {
    month: today.getMonth() + 1,
    year: today.getFullYear(),
  };
  const { t } = useTranslation();
  const { session } = useSession();
  const { control, watch } = useForm<LinesForm>({
    defaultValues: {
      period: initialPeriod,
    },
  });
  const [children, setChildren] = useState<NodeData>();
  const userId =
    session?.accessLevel === AccessLevel.ADMIN ? watch("userId") : session?.id;

  const getPath = (idToFind: string, node: UserWithChildren): string | null => {
    if (node.id === idToFind) {
      return node.id;
    }

    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        const id: string | null = getPath(idToFind, node.children[i]);
        if (id) {
          return node.id + "." + id;
        }
      }
    }

    return null;
  };

  const setNodeInPath = (
    ids: string[],
    node: NodeData,
    newNode: NodeData
  ): NodeData | null => {
    if (node.id === ids[0]) {
      if (ids.length === 1) {
        if (node.areChildrenOpen) {
          return {
            ...node,
            areChildrenOpen: false,
            children: [],
          };
        }

        return {
          ...node,
          areChildrenOpen: true,
          children: node.children
            ? [...node.children, ...newNode.children]
            : [...newNode.children],
        };
      }
      if (ids.length > 1) {
        const childrenIds = ids.filter((_, index) => index !== 0);
        for (let i = 0; i <= node.children.length; i++) {
          const currentNode = node.children[i];

          const deepNode = setNodeInPath(childrenIds, currentNode, newNode);

          if (JSON.stringify(currentNode) === JSON.stringify(deepNode)) {
            continue;
          }

          if (deepNode) {
            return {
              ...node,
              children: node.children.map((child) =>
                child.id === deepNode.id ? deepNode : child
              ),
            };
          }
        }
      }
    }

    return node;
  };

  const handleChildrenClick = async (nodeId: any) => {
    const response = await StemvidaService.getAllUserChildren(nodeId);
    if (response.children?.length === 0) return;
    const pathIds = getPath(nodeId, children!)?.split(".");

    const newTree = setNodeInPath(pathIds || [userId!], children!, response);
    setChildren(newTree!);
  };

  useEffect(() => {
    const getInitialState = async () => {
      if (userId) {
        setChildren(undefined);
        const response = await StemvidaService.getAllUserChildren(userId);
        setChildren({
          ...response,
          areChildrenOpen: true,
        });
      }
    };

    getInitialState();
  }, [userId]);

  return (
    <React.Fragment>
      <CRow className="mb-3 ">
        <CCol>
          <h1>{t("My line")}</h1>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CCard>
            <CCardBody>
              <CRow className="mb-5">
                <CForm inline className="w-100">
                  {session?.accessLevel === AccessLevel.ADMIN && (
                    <CCol xs="5">
                      <UserSelector
                        inline
                        label={t("user")}
                        control={control}
                        name="userId"
                      />
                    </CCol>
                  )}
                </CForm>
              </CRow>
              {children && (
                <OrganizationChart
                  datasource={children}
                  pan={true}
                  zoom={true}
                  collapsible={false}
                  NodeTemplate={(props: any) => (
                    <Node
                      {...props}
                      handleChildrenClick={handleChildrenClick}
                      canDisplayChildren={
                        session?.accessLevel === AccessLevel.ADMIN
                      }
                    />
                  )}
                />
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </React.Fragment>
  );
};

export default Line;
