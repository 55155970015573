import { cilUser } from "@coreui/icons";
import React, { useCallback, useState } from "react";
import { Control } from "react-hook-form";
import { OptionTypeBase } from "react-select";
import StemvidaService from "../../api/StemvidaService";
import Select from "../Select/Select";

interface UserSelectorProps {
  hasIcon?: boolean;
  label: string;
  control: Control<any>;
  name: string;
  inline?: boolean;
  defaultOptions?: OptionTypeBase[];
  required?: boolean;
}

const UserSelector: React.FC<UserSelectorProps> = ({
  hasIcon,
  label,
  control,
  name,
  inline,
  defaultOptions,
  required,
}) => {
  const [options, setOptions] = useState<OptionTypeBase[]>(
    defaultOptions || []
  );

  const fetchUsersByFilter = useCallback(async (newValue) => {
    const response = await StemvidaService.getUsersByParams(newValue);
    setOptions(
      response.map((d) => ({
        value: d.id,
        label: `${d.id} - ${d.name} ${
          d.dni ? `- ${d.dni?.replaceAll("-", "") || ""}` : ""
        }`,
      }))
    );
  }, []);

  const handleInputChange = (v: string) => {
    fetchUsersByFilter(v);
  };

  return (
    <Select
      inline={inline}
      icon={hasIcon && cilUser}
      options={options}
      isClereable
      required={required}
      label={label}
      control={control}
      name={name}
      onInputChange={handleInputChange}
    />
  );
};

export default UserSelector;
