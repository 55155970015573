import CIcon from "@coreui/icons-react";
import {
  CCol,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CInvalidFeedback,
  CLabel,
} from "@coreui/react";
import React, { Fragment } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OptionTypeBase } from "react-select";
import SelectComponent from "react-select/creatable";

interface SelectProps {
  icon?: any;
  label: string;
  options: OptionTypeBase[];
  isClereable?: boolean;
  handleOnChange?: (newString: string) => void;
  onInputChange?: (newValue: string) => void;
  defaultValue?: string;
  control: Control<any>;
  name: string;
  inline?: boolean;
  hideSelectedOptions?: boolean;
  required?: boolean;
}

const Select: React.FC<SelectProps> = ({
  icon,
  label,
  options,
  name,
  control,
  isClereable = false,
  defaultValue,
  inline,
  onInputChange,
  handleOnChange,
  hideSelectedOptions,
  required,
}) => {
  const { t } = useTranslation();
  return (
    <CCol className={inline ? "d-flex flex-row" : ""}>
      <CLabel className={inline ? "mr-3" : ""}>{label}</CLabel>
      <CInputGroup style={inline ? { display: "contents" } : {}}>
        {icon && (
          <CInputGroupAppend>
            <CInputGroupText>
              <CIcon content={icon} />
            </CInputGroupText>
          </CInputGroupAppend>
        )}
        <Controller
          name={name}
          control={control}
          rules={{ required }}
          render={({ field: { value, onChange }, fieldState }) => (
            <Fragment>
              <SelectComponent
                formatCreateLabel={(value) => value}
                isClearable={isClereable}
                placeholder={null}
                defaultValue={defaultValue}
                options={options}
                onChange={(e) => {
                  onChange(e?.value || null);
                  if (handleOnChange) {
                    handleOnChange(e.value);
                  }
                }}
                hideSelectedOptions={hideSelectedOptions}
                value={options.find((o) => o.value === value)}
                styles={{
                  container: () => ({
                    flex: "auto",
                  }),
                  control: (styles) => ({
                    ...styles,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    minWidth: "100%",
                  }),
                }}
                onInputChange={onInputChange}
              />
              {fieldState.error?.type === "required" && (
                <CInvalidFeedback>{t("requiredField")}</CInvalidFeedback>
              )}
            </Fragment>
          )}
        />
      </CInputGroup>
    </CCol>
  );
};

export default Select;
