import { useCallback, useState } from "react";

export interface Pagination {
  page: number;
  totalRows: number;
}

const useTablePagination = (itemsPerPage?: number) => {
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    totalRows: 0,
  });
  const [limit] = useState(itemsPerPage || 10);

  const changePage = useCallback((page: number, totalRows: number) => {
    setPagination((cp) => ({
      ...cp,
      page,
      totalRows,
    }));
  }, []);

  return {
    changePage,
    pagination: {
      page: pagination.page,
      totalRows: pagination.totalRows,
      limit,
    },
  };
};

export default useTablePagination;
