import { AccessLevel } from "../interfaces/globals/session";
import CommisionList from "../views/comissions/CommisionList";
import OrderList from "../views/orders/OrderList/OrderList";
import Login from "../views/auth/login/Login";
import CommisionsReport from "../views/Reports/CommisionsReport/CommisionsReport";
import UsersList from "../views/Users/UsersList/UserList";
import paths from "./paths";
import UserDetails from "../views/Users/userDetails/UserDetails";
import Profile from "../views/profile/Profile";
import PersonalSales from "../views/Sales/Personal/PersonalSales";
import ChildrenSales from "../views/Sales/Children/ChildrenSales";
import VolumePerLine from "../views/Lines/VolumePerLine/VolumePerLine";
import PaymentsReport from "../views/Reports/Payments/PaymentsReport";
import Line from "../views/Lines/Line/Line";

export enum ScreenType {
  PROTECTED = "PROTECTED",
  PUBLIC = "PUBLIC",
}

export const screens = [
  {
    path: paths.login,
    component: Login,
  },
  {
    path: paths.orders,
    component: OrderList,
    accessLevels: [AccessLevel.ADMIN],
  },
  {
    path: paths.commisions,
    component: CommisionList,
    accessLevels: [AccessLevel.ADMIN],
  },
  {
    path: paths.personalSales,
    component: PersonalSales,
    accessLevels: [AccessLevel.USER, AccessLevel.ADMIN],
  },
  {
    path: paths.childrenSales,
    component: ChildrenSales,
    accessLevels: [AccessLevel.USER, AccessLevel.ADMIN],
  },
  {
    path: paths.profile,
    component: Profile,
    accessLevels: [AccessLevel.USER, AccessLevel.ADMIN],
  },
  {
    path: paths.commisionsReports,
    component: CommisionsReport,
    accessLevels: [AccessLevel.ADMIN],
  },
  {
    path: paths.paymentsReports,
    component: PaymentsReport,
    accessLevels: [AccessLevel.ADMIN],
  },
  {
    path: paths.users,
    component: UsersList,
    accessLevels: [AccessLevel.ADMIN],
  },
  {
    path: paths.userDetails,
    component: UserDetails,
    accessLevels: [AccessLevel.ADMIN],
  },
  {
    path: paths.volumePerLine,
    component: VolumePerLine,
    accessLevels: [AccessLevel.ADMIN, AccessLevel.USER],
  },
  {
    path: paths.line,
    component: Line,
    accessLevels: [AccessLevel.ADMIN, AccessLevel.USER],
  },
];
