import { CCol, CFormGroup, CLabel, CRow } from "@coreui/react";
import React from "react";
import { FieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  NewOrderFormData,
  paymentMethodFormData,
  PaymentMethods,
} from "./interfaces";

interface PaymentMethodCheckboxesProps {
  selectedPaymentMethods: FieldArray<NewOrderFormData, "paymentMethod">[];
  insert: (index: number, value: Partial<paymentMethodFormData>) => void;
  remove: (index: number) => void;
  wallet: number;
}

const PaymentMethodCheckboxes: React.FC<PaymentMethodCheckboxesProps> = ({
  selectedPaymentMethods,
  insert,
  remove,
  wallet,
}) => {
  const { t } = useTranslation();

  return (
    <CFormGroup row>
      <CCol>
        <CLabel>{t("payment methods")}</CLabel>
      </CCol>
      <CRow>
        <CCol>
          {Object.keys(PaymentMethods).map((method) => (
            <CFormGroup
              variant="custom-checkbox"
              className="mr-4"
              inline
              key={method}
            >
              <input
                className="custom-control-input"
                id={method}
                type="checkbox"
                value={method}
                onChange={(v) => {
                  if (v.target.checked) {
                    return insert(selectedPaymentMethods.length, {
                      method,
                    });
                  }

                  remove(
                    selectedPaymentMethods.findIndex((m) => m.method === method)
                  );
                }}
              />
              <CLabel variant="custom-checkbox" htmlFor={method}>
                {t(method)}
              </CLabel>
              {method === PaymentMethods.wallet && (
                <p>
                  ({t("available")} ${wallet})
                </p>
              )}
            </CFormGroup>
          ))}
        </CCol>
      </CRow>
    </CFormGroup>
  );
};

export default PaymentMethodCheckboxes;
