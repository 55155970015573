import React, { Dispatch } from "react";
import { CButton, CCol, CForm, CFormGroup, CRow } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { OptionTypeBase } from "react-select";
import PaymentMethodCheckboxes from "./PaymentMethodCheckboxes";
import PaymentMethodInputs from "./PaymentMethodInputs";
import ProductInputs from "./ProductInputs";
import useOrderForm from "./useOrderForm";
import UserSelector from "../../../../components/UserSelector/UserSelector";
import { CreateOrderModalState } from "../CreateOrderModal";
import { USER_STATUS } from "../../../../interfaces/entities/User";

interface OrderFormProps {
  userOption?: OptionTypeBase;
  setReceipt: Dispatch<React.SetStateAction<number | undefined>>;
  setUser: (newValue: OptionTypeBase) => void;
  handleModalStateChange: (newState: CreateOrderModalState) => void;
}

const OrderForm: React.FC<OrderFormProps> = ({
  userOption,
  handleModalStateChange,
  setReceipt,
}) => {
  const { t } = useTranslation();
  const {
    insert,
    onSubmit,
    productFields,
    products,
    register,
    remove,
    selectedPaymentMethods,
    total,
    watchPaymentMethod,
    watchProducts,
    control,
    validationError,
    user,
    createOrderError,
    formState: { isSubmitting },
  } = useOrderForm({
    handleModalStateChange,
    userId: userOption?.value,
    setReceipt,
  });

  return (
    <React.Fragment>
      <h2 className="mb-4">{t("New Order")}</h2>
      <CForm onSubmit={onSubmit}>
        <CFormGroup row>
          <UserSelector
            defaultOptions={userOption && [userOption]}
            hasIcon
            label={t("distributor")}
            control={control}
            name="userId"
            required
          />
        </CFormGroup>

        <div className="mb-5">
          <h6>
            {t("Type")}:{" "}
            <span className={user?.type === "customer" ? "customer-label" : ""}>
              {t(user?.type || "")}
            </span>
          </h6>
          <h6>
            {t("Status")}:{" "}
            <span className={user?.status === "active" ? "" : "inactive-label"}>
              {t(
                (user &&
                  (user?.status === USER_STATUS.ACTIVE
                    ? "active"
                    : "inactive")) ||
                  ""
              )}
            </span>
          </h6>
          <h6>
            {t("sponsor")}: {user?.ancestors[user.ancestors.length - 1]}
          </h6>
        </div>

        <ProductInputs
          control={control}
          productFields={productFields}
          products={products}
          register={register}
          watchProducts={watchProducts}
        />

        <CRow className="justify-content-end mb-3">
          <CCol md="2">
            <p>{t("Discount")}</p>
          </CCol>
          <CCol md="2">
            <input
              className="form-control"
              min={0}
              type="number"
              {...register("discount", {
                valueAsNumber: true,
              })}
            />
          </CCol>
        </CRow>

        <CRow className="justify-content-end mb-3">
          <CCol md="2">
            <p>{t("Shipping")}</p>
          </CCol>
          <CCol md="2">
            <input
              className="form-control"
              type="number"
              min={0}
              {...register("shipping", {
                valueAsNumber: true,
              })}
            />
          </CCol>
        </CRow>

        <CRow className="mb-5">
          <CCol className="d-flex justify-content-end">
            <h3>
              Total: <span>${total || 0}</span>
            </h3>
          </CCol>
        </CRow>

        <PaymentMethodCheckboxes
          selectedPaymentMethods={selectedPaymentMethods}
          insert={insert}
          remove={remove}
          wallet={user?.wallet || 0}
        />
        {validationError && <p className="text-danger">{validationError}</p>}

        <PaymentMethodInputs
          watchPaymentMethod={watchPaymentMethod}
          register={register}
          selectedPaymentMethods={selectedPaymentMethods}
        />

        {createOrderError && (
          <p className="text-danger">{t(createOrderError)}</p>
        )}
        <CRow>
          <CCol className="d-flex justify-content-end">
            <CButton
              color="success"
              size="lg"
              type="submit"
              disabled={isSubmitting}
            >
              {t("save")}
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </React.Fragment>
  );
};

export default OrderForm;
