import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import StemvidaService from "../../api/StemvidaService";
import { MonthAndYear } from "../../interfaces/globals/DateRange";
import GetUserCommisions from "../../interfaces/responses/userCommisions";

const useCommisionsTables = (userId: string, period: MonthAndYear) => {
  const { t } = useTranslation();
  const [details, setDetails] = useState<number[]>([]);
  const [commisions, setCommisions] = useState<GetUserCommisions>();
  const tableColumns = [
    {
      key: "userThatGeneratedOrder",
      label: t("user"),
    },
    {
      key: "status",
      label: t("commisionState"),
    },
    {
      key: "date",
      label: t("date"),
    },
    {
      key: "bonusAmount",
      label: t("ammount"),
    },
  ];

  useEffect(() => {
    const getUserCommisions = async () => {
      const response = await StemvidaService.getUserCommisions(userId, period);
      setCommisions({
        ...response,
        details: response.details.map((bonus) => ({
          ...bonus,
          type: t(bonus.type),
        })),
      });
    };

    getUserCommisions();
  }, [t, userId, period]);

  const toggleDetails = (index: number) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  return { tableColumns, commisions, toggleDetails, details };
};

export default useCommisionsTables;
