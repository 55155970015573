import React from "react";
import "./assets/scss/style.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { screens } from "./routes";
import initInternalize from "./utils/i18n";
import ProtectedRoute from "./routes/ProtectedRoute";
import paths from "./routes/paths";
import { OrderListProvider } from "./contexts/OrderListContext";

initInternalize();

const App: React.FC = () => {
  return (
    <OrderListProvider>
      <Router>
        <Switch>
          {screens.map((screen, key) =>
            screen.accessLevels && screen.accessLevels.length > 0 ? (
              <ProtectedRoute
                key={key}
                exact
                path={screen.path}
                component={screen.component}
                accessLevels={screen.accessLevels}
              />
            ) : (
              <Route
                exact
                path={screen.path}
                key={key}
                component={screen.component}
              />
            )
          )}
          <Redirect to={paths.orders} />
        </Switch>
      </Router>
    </OrderListProvider>
  );
};

export default App;
