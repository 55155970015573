import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CFormGroup,
  CPagination,
  CRow,
} from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import TextInput from "../../../components/TextInput/TextInput";
import { User } from "../../../interfaces/entities/User";
import useUserList from "./useUserList";

const UsersList: React.FC = () => {
  const {
    handlePageChange,
    users,
    pagination,
    tableColumns,
    onRowClick,
    register,
    onFilterChange,
  } = useUserList();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <CRow className="mb-3 ">
        <CCol>
          <h1>{t("User list")}</h1>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CCard>
            <CCardBody>
              <CFormGroup row>
                <TextInput
                  register={register("filter")}
                  label={t("filter")}
                  autoComplete={false}
                  onChange={onFilterChange}
                />
              </CFormGroup>
              <CDataTable
                items={users}
                fields={tableColumns}
                itemsPerPage={5}
                scopedSlots={{
                  actions: (item: User) => (
                    <td>
                      <CButton
                        size="lg"
                        color="success"
                        onClick={() => onRowClick(item.id)}
                      >
                        {t("Details")}
                      </CButton>
                    </td>
                  ),
                }}
              />
              <CRow>
                <CCol className="d-flex justify-content-center">
                  <CPagination
                    activePage={pagination.page}
                    pages={Math.ceil(pagination.totalRows / pagination.limit)}
                    onActivePageChange={handlePageChange}
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </React.Fragment>
  );
};

export default UsersList;
