const paths = {
  login: "/login",
  orders: "/orders",
  commisions: "/commisions",
  personalSales: "/sales/personal",
  childrenSales: "/sales/children",
  profile: "/profile",
  users: "/users",
  userDetails: "/users/:id",
  home: "/",
  commisionsReports: "/reports/commissions",
  paymentsReports: "/reports/payments",
  volumePerLine: "/volume-per-line",
  line: "/line",
};

export default paths;
