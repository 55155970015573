import {
  CCard,
  CCardBody,
  CCol,
  CCollapse,
  CDataTable,
  CRow,
} from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { MonthAndYear } from "../../interfaces/globals/DateRange";
import {
  Bonus,
  UserCommision,
} from "../../interfaces/responses/userCommisions";
import useCommisionsTables from "./useCommisionsTable";

export interface CommisionsTableProps {
  userId: string;
  period: MonthAndYear;
}

const SummaryCard: React.FC<{ value?: string | number; label: string }> = ({
  label,
  value,
}) => (
  <CCol cols={12} lg={3}>
    <CCard>
      <CCardBody className="text-center">
        <h4 style={{ color: "#B1B1B1" }}>{label}</h4>
        <h2>{value}</h2>
      </CCardBody>
    </CCard>
  </CCol>
);

const CommisionsTable: React.FC<CommisionsTableProps> = ({
  userId,
  period,
}) => {
  const { t } = useTranslation();
  const { tableColumns, toggleDetails, details, commisions } =
    useCommisionsTables(userId, period);

  const totalCommisions =
    commisions?.details.reduce((a, b) => a + b.total, 0) || 0;

  return (
    <React.Fragment>
      <CRow className="mt-3">
        <SummaryCard
          label={t("highest position reached")}
          value={commisions?.highestPositionReached}
        />
        <SummaryCard
          label={t("current month range")}
          value={commisions?.currentPositionReached}
        />
        <SummaryCard label={t("points")} value={commisions?.points} />
        <SummaryCard label={t("total period")} value={totalCommisions} />
      </CRow>

      <CDataTable
        items={commisions?.details}
        clickableRows
        outlined
        hover
        onRowClick={(item: UserCommision, index: number) =>
          item?.items && toggleDetails(index)
        }
        fields={[
          {
            key: "type",
            label: t("type"),
          },
          {
            key: "total",
            label: "Total",
          },
        ]}
        scopedSlots={{
          details: (item: UserCommision, index: number) => {
            return (
              <CCollapse show={details.includes(index)}>
                <CCardBody>
                  <CDataTable
                    items={item.items}
                    fields={tableColumns}
                    scopedSlots={{
                      date: (item: Bonus) => (
                        <td>{new Date(item.createdAt).toDateString()}</td>
                      ),
                      bonusAmount: (item: Bonus) => (
                        <td>{item.bonusAmount.toFixed(2)}</td>
                      ),
                      userThatGeneratedOrder: (item: Bonus) => (
                        <td>
                          {item.userThatGeneratedOrder?.name} -{" "}
                          {item.userThatGeneratedOrder?.id}
                        </td>
                      ),
                    }}
                  />
                </CCardBody>
              </CCollapse>
            );
          },
        }}
      />
    </React.Fragment>
  );
};

export default CommisionsTable;
