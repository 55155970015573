import { Order } from "../../interfaces/entities/Order";

const calculateTotalOrder = (order: Order) => {
  const methodsTotal = order.paymentMethod.reduce(
    (previous, current) => previous + current.amount,
    0
  );
  return methodsTotal + order.shipping - order.discount;
};

export default calculateTotalOrder;
