import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CRow,
} from "@coreui/react";
import { cilUser, cilLockLocked } from "@coreui/icons";
import { useTranslation } from "react-i18next";
import StemvidaService from "../../../api/StemvidaService";
import { useForm } from "react-hook-form";
import TextInput from "../../../components/TextInput/TextInput";
import { useHistory, useLocation } from "react-router-dom";
import paths from "../../../routes/paths";
import { sessionInfoCookie } from "../../../hooks/useSession/constants";
import { AccessLevel } from "../../../interfaces/globals/session";

interface LoginFormData {
  id: string;
  password: string;
}

const Login: React.FC = () => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<LoginFormData>();
  const history = useHistory();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const redirectUrl = urlParams.get("redirect");
  const [loginError, setLoginError] = useState<string>();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await StemvidaService.login(data);
      localStorage.setItem(sessionInfoCookie, JSON.stringify(response));

      const homePath =
        response.accessLevel === AccessLevel.ADMIN
          ? paths.orders
          : paths.personalSales;

      history.push(redirectUrl || homePath);
    } catch (error: any) {
      if (error.response.status === 400) {
        return setLoginError("Invalid id or password");
      }

      setLoginError(error.message);
    }
  });

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol sm="10" md="8" lg="6" xl="4">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={onSubmit}>
                    <h1>{t("login")}</h1>
                    <CFormGroup row>
                      <TextInput
                        register={register("id")}
                        label={t("user ID")}
                        icon={cilUser}
                      />
                    </CFormGroup>
                    <CFormGroup row>
                      <TextInput
                        register={register("password")}
                        label={t("password")}
                        icon={cilLockLocked}
                        type="password"
                      />
                    </CFormGroup>
                    {loginError && <p>{t(loginError)}</p>}
                    <CRow>
                      <CCol xs="6">
                        <CButton color="primary" className="px-4" type="submit">
                          {t("login")}
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
