import React from "react";
import { CButton, CCol, CRow } from "@coreui/react";
import { useTranslation } from "react-i18next";
import Logo from "./../../../assets/images/logo.png";
import useReceipt from "./useReceipt";
import { formatDate } from "../../../utils/date";

interface ReceiptProps {
  id: number;
}

const Receipt: React.FC<ReceiptProps> = ({ id }) => {
  const { handlePrint, subTotal, order, componentRef } = useReceipt(id);
  const { t } = useTranslation();

  if (!order) return <p>Carganndo</p>;

  return (
    <React.Fragment>
      <CButton size="lg" color="success" className="mb-5" onClick={handlePrint}>
        {t("Print")}
      </CButton>

      <div ref={componentRef}>
        <CRow className="mb-5">
          <CCol>
            <img src={Logo} alt="logo" width="100%" />
          </CCol>
          <CCol>
            <p className="font-weight-bold text-right">{t("Invoice")}</p>
            <p className="text-right">
              {t("Order Date")}: {formatDate(order.createdAt)}
            </p>
            <p className="text-right">
              {t("Distributor ID")}: {order.user.id}
            </p>
            <p className="text-right">
              {t("Invoice ID")}: {order.orderId}
            </p>
            <p className="text-right">NCF: {order.ncf}</p>
          </CCol>
        </CRow>
        <CRow className="mb-5 receipt-header">
          <CCol xs={7}>
            <p>Stem Global Connexions Inc.</p>
            <p>Av. 27 de Febrero # 29. Miraflores. D.N.</p>
            <p>stemglobalconnexionsrd@gmail.com</p>
            <p>Rep. Dom.: (809) 688-4333 / (809) 688-5333</p>
            <p>Whatsapp +1 (829) 451-6368</p>
          </CCol>
          <CCol>
            <p className="font-weight-bold">{t("Distributor details")}</p>
            <p>
              {t("Name")}: {order.user.name}
            </p>
            <p>
              {t("Class")}: {t(order.user.type)}
            </p>
            <p className="font-weight-bold">{t("Shipping Address")}:</p>
            <p>{t("Order Delivered Personally")}</p>
            <p className="font-weight-bold">{t("Payment methods")}</p>
            {order.paymentMethod.map(({ method, amount }) => (
              <p>
                {t(method)} - ${amount}
              </p>
            ))}
          </CCol>
        </CRow>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{t("Name")}</th>
              <th scope="col">{t("Quantity")}</th>
              <th scope="col">{t("Price")}</th>
              <th scope="col">{t("Subtotal")}</th>
            </tr>
          </thead>
          <tbody>
            {order.products.map((product) => (
              <tr>
                <th scope="row">{product.name}</th>
                <td>{product.quantity}</td>
                <td>{product.price}</td>
                <td>{product.price * product.quantity}</td>
              </tr>
            ))}
            <tr>
              <td colSpan={3}>Sub-total</td>
              <td>{subTotal}</td>
            </tr>
            <tr>
              <td colSpan={3}>{t("Shipping")}</td>
              <td>{order.shipping}</td>
            </tr>
            <tr>
              <td colSpan={3}>{t("Discount")}</td>
              <td>{order.discount}</td>
            </tr>
            <tr>
              <td colSpan={3}>Total</td>
              <td>{subTotal + order.shipping - order.discount}</td>
            </tr>
          </tbody>
        </table>
        <h2 className="text-center mt-5 text-success">
          {t("Visit our web page")}: www.globalconnexionsdominicana.com
        </h2>
        <h1 className="text-center mt-5">{t("Thankyou for your purchase")}</h1>
      </div>
    </React.Fragment>
  );
};

export default Receipt;
