import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import StemvidaService from "../../../api/StemvidaService";
import { MonthAndYear } from "../../../interfaces/globals/DateRange";
import { MonthlyCommission } from "../../../interfaces/responses/getMontlyCommissions";

const today = new Date();
const initialPeriod = {
  month: today.getMonth() + 1,
  year: today.getFullYear(),
};

const useCommissionsReport = () => {
  const [period, setPeriod] = useState<MonthAndYear>(initialPeriod);
  const [isClosingMonth, setIsClosingMonth] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [unavailableCommissions, setUnavailableCommissions] = useState<
    MonthlyCommission[]
  >([]);
  const [availableCommisions, setAvailableCommisions] = useState<
    MonthlyCommission[]
  >([]);

  const { control } = useForm<MonthAndYear>({
    defaultValues: initialPeriod,
  });

  const total = availableCommisions.reduce(
    (previous, current) => previous + current.amount,
    0
  );

  const getMonthlyCommissions = useCallback(async () => {
    const response = await StemvidaService.getMonthlyCommissions(period);
    setAvailableCommisions(response.bonuses.filter((c) => c.amount > 30));
    setUnavailableCommissions(response.bonuses.filter((c) => c.amount <= 30));
    setIsClosed(response.isClosed);
  }, [period]);

  useEffect(() => {
    getMonthlyCommissions();
  }, [getMonthlyCommissions]);

  const closeMonth = async () => {
    setIsClosingMonth(true);
    await StemvidaService.closeMonth(period);
    await getMonthlyCommissions();
    setIsClosingMonth(false);
  };

  return {
    control,
    setPeriod,
    total,
    period,
    closeMonth,
    availableCommisions,
    unavailableCommissions,
    isClosingMonth,
    isClosed,
  };
};

export default useCommissionsReport;
