import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout: React.FC = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(true);

  return (
    <div className="c-app c-default-layout">
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className="c-wrapper">
        <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
        <div className="c-body px-3 pt-3">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
