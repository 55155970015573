import { useEffect, useRef } from "react";
import { useState } from "react";
import { useReactToPrint } from "react-to-print";
import StemvidaService from "../../../api/StemvidaService";
import { Order } from "../../../interfaces/entities/Order";

const useReceipt = (id: number) => {
  const [order, setOrder] = useState<Order>();
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "p-5 mt-5 receipt-preview",
    documentTitle: "factura",
  });

  const subTotal =
    order?.products
      .map((product) => product.quantity * product.price)
      .reduce((previous, current) => previous + current, 0) || 0;

  useEffect(() => {
    const getOrder = async () => {
      const response = await StemvidaService.getOrder(id);
      setOrder(response);
    };

    getOrder();
  }, [id]);

  return { subTotal, handlePrint, order, componentRef };
};

export default useReceipt;
