export interface User {
  id: string;
  name: string;
  dateOfBirth?: Date;
  type: Usertype;
  dni: string;
  email?: string;
  address?: string;
  country?: string;
  state?: string;
  city?: string;
  phone?: string;
  cellphone?: string;
  sponsor?: string;
  ancestors: string[];
  wallet?: number;
  active: boolean;
  status: USER_STATUS;
}

export enum USER_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export type Usertype = "star" | "platinum" | "customer";

export interface UserWithChildren extends User {
  children: UserWithChildren[];
}
