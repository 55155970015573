import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormGroup,
  CRow,
} from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import TextInput from "../../../components/TextInput/TextInput";
import useUserDetails from "./useUserDetails";

const UserDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const {
    user,
    generatePassword,
    temporaryPassword,
    FormFieldsRegisters,
    onSubmit,
  } = useUserDetails(id);

  if (!user) return <h1>Loading</h1>;

  return (
    <div>
      <p>
        {t("Name")}: {user.name}
      </p>
      <p>Id: {user.id}</p>
      <p>
        {t("phone number")}: {user.phone}
      </p>
      <p>
        {t("cellphone number")}: {user.cellphone}
      </p>
      <p>
        {t("active")}: {user.status === "active" ? "activo" : "Inactivo"}
      </p>
      <p>
        {t("sponsor")}: {user?.ancestors[user.ancestors.length - 1]}
      </p>
      <CRow>
        <div>
          <CButton color="primary" type="submit" onClick={generatePassword}>
            {t("Generate temporary password")}
          </CButton>
        </div>
        <CCol>
          {temporaryPassword && (
            <h5>
              {t("Temporary password")}: {temporaryPassword}
            </h5>
          )}
        </CCol>
      </CRow>

      <CRow className="mt-3">
        <CCol xs="4">
          <CCard>
            <CCardBody>
              <h1>{t("wallet")}</h1>
              <h3>Balance: {user.wallet}</h3>
              <CForm onSubmit={onSubmit}>
                <CFormGroup row>
                  <TextInput
                    label={t("Amount to be discounted")}
                    register={FormFieldsRegisters.amountToDecrease}
                  />
                </CFormGroup>
                <CButton
                  color="primary"
                  className="px-4"
                  type="submit"
                  disabled={user.wallet === 0}
                >
                  {t("discount")}
                </CButton>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};

export default UserDetails;
