import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

const usePrint = ({ documentTitle }: { documentTitle: string }) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const PRINT_BODY_CLASS = "payment-method-report";

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: `p-5 ${PRINT_BODY_CLASS}`,
    documentTitle: documentTitle,
  });

  return { componentRef, handlePrint, PRINT_BODY_CLASS };
};

export default usePrint;
