import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormGroup,
  CRow,
} from "@coreui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "../../../components/Select/Select";
import { ADMIN_FEES, DISCOUNT } from "../../../constants/Fees";
import { months, years } from "../../../constants/monthsAndYears";
import usePrint from "../../../hooks/useSession/usePrint";
import useCommissionsReport from "../CommisionsReport/useCommissionsReport";

const PaymentsReport: React.FC = () => {
  const { t } = useTranslation();
  const { control, setPeriod, availableCommisions, period } =
    useCommissionsReport();
  const [rate, setRate] = useState(54);

  const nextMonth = useMemo(
    () => months[period.month === 12 ? 0 : period.month],
    [period.month]
  );

  const { componentRef, handlePrint } = usePrint({
    documentTitle: `Pago de comisiones del mes de ${period.month} para pagar en ${nextMonth}`,
  });

  const total = useMemo(() => {
    const totalNumber = availableCommisions.reduce((a, commision) => {
      const percentageDiscount = commision.amount * DISCOUNT;
      const adminFees = 3;
      const totalDiscount = -1 * percentageDiscount - adminFees;
      const totalCommision = commision.amount + totalDiscount;
      return a + totalCommision * rate;
    }, 0);
    return totalNumber.toFixed(2);
  }, [availableCommisions, rate]);

  const printedHeader = useMemo(() => {
    const month = t(
      months.find((month) => month.value === period.month)?.label || ""
    );

    return `Comisiones del mes de ${month} del ${period.year} para pagar en ${t(
      nextMonth.label
    )}`;
  }, [nextMonth.label, period.month, period.year, t]);

  return (
    <React.Fragment>
      <CRow className="mb-3 ">
        <CCol>
          <h1>{t("Commissions payments")}</h1>
        </CCol>
        <CCol className="d-flex justify-content-end">
          <CButton size="lg" color="success" onClick={handlePrint}>
            {t("Print")}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CCard>
            <CCardBody>
              <CRow className="mb-3">
                <CCol xs="12">
                  <CForm>
                    <CFormGroup row>
                      <p>{t("Commisions of the month")}</p>
                      <Select
                        control={control}
                        inline
                        name="month"
                        label=""
                        hideSelectedOptions
                        options={months.map((month) => ({
                          label: t(month.label),
                          value: month.value,
                        }))}
                        handleOnChange={(v) => {
                          setPeriod((p) => ({
                            ...p,
                            month: Number(v),
                          }));
                        }}
                      />
                      <p>{t("of")}</p>
                      <CCol className={"d-flex flex-row"}>
                        <Select
                          control={control}
                          inline
                          name="year"
                          label=""
                          hideSelectedOptions
                          options={years}
                          handleOnChange={(v) => {
                            setPeriod((p) => ({
                              ...p,
                              year: Number(v),
                            }));
                          }}
                        />
                      </CCol>
                      <p>
                        {t("to pay in")} {t(nextMonth.label)}{" "}
                        {t("with rate of")}
                      </p>
                      <CCol className="d-flex flex-row">
                        <input
                          type="number"
                          min={0}
                          className={`form-control`}
                          onChange={(e) => setRate(Number(e.target.value))}
                          value={rate}
                        />
                      </CCol>
                    </CFormGroup>
                  </CForm>
                </CCol>
              </CRow>
              <div ref={componentRef}>
                <style type="text/css" media="print">
                  {"@page { size: landscape; }"}
                </style>
                <h3 className="print-header">{printedHeader}</h3>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{t("user ID")}</th>
                      <th scope="col">{t("Name")}</th>
                      <th scope="col">{t("Country")}</th>
                      <th scope="col">{t("Commission")}</th>
                      <th scope="col">{t("Gastos adm.")}</th>
                      <th scope="col">{t("Comision neta")}</th>
                      <th scope="col">Conversión ${rate}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {availableCommisions.map((commision, key) => {
                      const percentageDiscount = commision.amount * DISCOUNT;
                      const adminFees = ADMIN_FEES;
                      const totalDiscount = -1 * percentageDiscount - adminFees;
                      const total = commision.amount + totalDiscount;

                      return (
                        <tr key={key}>
                          <td>{commision.userId}</td>
                          <td>{commision.name}</td>
                          <td>DO</td>
                          <td>{commision.amount}</td>
                          <td>{adminFees}</td>
                          <td>{total.toFixed(2)}</td>
                          <td>{(total * rate).toFixed(2)}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan={6} className="font-weight-bold">
                        Total
                      </td>
                      <td colSpan={3}>{total}</td>
                    </tr>
                    <tr>
                      <td colSpan={9} />
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </React.Fragment>
  );
};

export default PaymentsReport;
