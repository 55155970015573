import React from "react";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import useSession from "../../hooks/useSession/useSession";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import paths from "../../routes/paths";

interface HeaderProps {
  showSidebar: boolean;
  setShowSidebar: (val: any) => void;
}

const Header: React.FC<HeaderProps> = ({ setShowSidebar, showSidebar }) => {
  const { t } = useTranslation();
  const { session, logout } = useSession();
  const history = useHistory();

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(showSidebar)
      ? true
      : "responsive";
    setShowSidebar(val);
  };

  return (
    <CHeader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />

      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <CIcon name="logo" height="48" alt="Logo" />
      </CHeaderBrand>

      <CHeaderNav className="ml-auto">
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink onClick={() => history.push(paths.profile)}>
            {session?.name}
          </CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink onClick={logout}>{t("logout")}</CHeaderNavLink>
        </CHeaderNavItem>
      </CHeaderNav>
    </CHeader>
  );
};

export default Header;
