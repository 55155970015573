import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { User, UserWithChildren } from "../interfaces/entities/User";
import { Order } from "../interfaces/entities/Order";
import { Product } from "../interfaces/entities/Product";
import * as Responses from "../interfaces/responses";
import * as Payloads from "../interfaces/payloads";
import { sessionInfoCookie } from "../hooks/useSession/constants";
import { MonthAndYear } from "../interfaces/globals/DateRange";
import { Volume } from "../interfaces/entities/Volume";

class StemvidaService {
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      withCredentials: true,
    });

    this.client.interceptors.response.use(
      undefined,
      async (error: AxiosError) => {
        if (error.response?.status === 401 || error.response?.status === 403) {
          localStorage.removeItem(sessionInfoCookie);
          const currentPath = window.location.pathname;
          window.location.pathname = `/login?redirect=${currentPath}`;
        }
        throw error;
      }
    );
  }

  async createUser(payload: Payloads.CreateUser) {
    const response: AxiosResponse<Responses.CreateUser> =
      await this.client.request({
        method: "POST",
        data: payload,
        url: "/users",
      });

    return response.data;
  }

  async getUsersByIdMatch(id: string) {
    const response: AxiosResponse<Responses.SearchUser[]> =
      await this.client.request({
        method: "GET",
        url: `/users/${id}`,
      });

    return response.data;
  }

  async getUsersByParams(filter: string) {
    const response: AxiosResponse<Responses.SearchUser[]> =
      await this.client.request({
        method: "GET",
        url: `/users/params`,
        params: {
          filter,
        },
      });

    return response.data;
  }

  async getProducts() {
    const response: AxiosResponse<Product[]> = await this.client.request({
      method: "GET",
      url: "/products",
    });

    return response.data;
  }

  async getUserCommisions(userId: string, period: MonthAndYear) {
    const response: AxiosResponse<Responses.GetUserCommisions> =
      await this.client.request({
        method: "GET",
        url: `/bonuses?userId=${userId}&month=${period.month}&year=${period.year}`,
      });

    return response.data;
  }

  async getUser(userId: string) {
    const response: AxiosResponse<User> = await this.client.request({
      method: "GET",
      url: `/users/user/${userId}`,
    });

    return response.data;
  }

  async getOrder(id: number) {
    const response: AxiosResponse<Order> = await this.client.request({
      method: "GET",
      url: `/orders/${id}`,
    });

    return {
      ...response.data,
      createdAt: new Date(response.data.createdAt),
    };
  }

  async createOrder(payload: Payloads.CreateOrder) {
    const response: AxiosResponse<Order> = await this.client.request({
      method: "POST",
      data: payload,
      url: "/orders",
    });

    return response.data;
  }

  async getOrders(payload: Payloads.GetOrders) {
    const objectKeys = Object.entries(payload);
    const queryParams = objectKeys.map((pair) =>
      pair.map(encodeURIComponent).join("=")
    );
    const formattedUriParams = queryParams.join("&");

    const response: AxiosResponse<Responses.GetOrders> =
      await this.client.request({
        method: "GET",
        url: `/orders?${formattedUriParams}`,
      });

    return {
      ...response.data,
      data: response.data.data.map((order) => ({
        ...order,
        createdAt: new Date(order.createdAt),
      })),
    };
  }

  async login(payload: Payloads.Login) {
    const response = await this.client.request({
      method: "POST",
      url: "/login",
      data: payload,
    });
    return response.data;
  }

  async getMonthlyCommissions(period: { year: number; month: number }) {
    const objectKeys = Object.entries(period);
    const queryParams = objectKeys.map((pair) =>
      pair.map(encodeURIComponent).join("=")
    );
    const formattedUriParams = queryParams.join("&");

    const response: AxiosResponse<Responses.GetMonthlyCommisions> =
      await this.client.request({
        method: "GET",
        url: `/reports/monthlycommisions?${formattedUriParams}`,
      });

    return response.data;
  }

  async logout() {
    await this.client.request({
      method: "POST",
      url: "logout",
    });
  }

  async getUserList(payload: Payloads.GetUsersList) {
    const queryParams = Object.entries(payload)
      .map((pair) => pair.map(encodeURIComponent).join("="))
      .join("&");

    const response: AxiosResponse<Responses.GetUserList> =
      await this.client.request({
        method: "GET",
        url: `/users?${queryParams}`,
      });

    return {
      ...response.data,
      data: response.data.data,
    };
  }

  async getUserDetail(userId: string) {
    const response: AxiosResponse<User> = await this.client.request({
      method: "GET",
      url: `/users/user/${userId}`,
    });

    return response.data;
  }

  async changePassword(newPassword: string) {
    const response: AxiosResponse<string> = await this.client.request({
      method: "PUT",
      url: `/profile/password`,
      data: {
        newPassword,
      },
    });

    return response.data;
  }

  async closeMonth(period: MonthAndYear) {
    await this.client.request({
      method: "POST",
      url: "/endOfMonth",
      data: period,
    });
  }

  async generateTemporaryPassword(userId: string) {
    const response: AxiosResponse<string> = await this.client.request({
      method: "PUT",
      url: `profile/password/${userId}`,
    });

    return response.data;
  }

  async getPersonalOrders(
    userId: string,
    pagination: { page: number; itemsPerPage: number },
    period: MonthAndYear
  ) {
    const response: AxiosResponse<{
      total: number;
      orders: Order[];
    }> = await this.client.request({
      method: "GET",
      url: `/orders/personal/${userId}?page=${pagination.page}&itemsPerPage=${pagination.itemsPerPage}&month=${period.month}&year=${period.year}`,
    });

    return response.data;
  }

  async getChildrenOrders(
    userId: string,
    pagination: { page: number; itemsPerPage: number },
    period: MonthAndYear
  ) {
    const response: AxiosResponse<{
      total: number;
      orders: Order[];
    }> = await this.client.request({
      method: "GET",
      url: `/orders/children/${userId}?page=${pagination.page}&itemsPerPage=${pagination.itemsPerPage}&month=${period.month}&year=${period.year}`,
    });

    return response.data;
  }

  async decreaseWallet(payload: { userId: string; amountToDecrease: number }) {
    const response: AxiosResponse<{ newBalance: number }> =
      await this.client.request({
        method: "POST",
        url: "/wallet/decreaseWallet",
        data: payload,
      });

    return response.data;
  }

  async getAllUserChildren(userId: string) {
    const response: AxiosResponse<UserWithChildren> = await this.client.request(
      {
        method: "GET",
        url: `/users/${userId}/children`,
      }
    );

    return response.data;
  }

  async getUserAndChildrenVolume(userId: string, period: MonthAndYear) {
    const response: AxiosResponse<Volume> = await this.client.request({
      method: "GET",
      url: `/volumes?userId=${userId}&month=${period.month}&year=${period.year}`,
    });

    return response.data;
  }
}

export default new StemvidaService();
