import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import StemvidaService from "../../../api/StemvidaService";
import { User } from "../../../interfaces/entities/User";

interface DiscountWalletForm {
  amountToDecrease: number;
}

const useUserDetails = (id: string) => {
  const [user, setUser] = useState<User>();
  const [temporaryPassword, setTemporaryPassword] = useState<string>();
  const { register, handleSubmit } = useForm<DiscountWalletForm>();

  const FormFieldsRegisters = {
    amountToDecrease: register("amountToDecrease", {
      required: true,
      pattern: {
        value: /[0-9]+/,
        message: "only number",
      },
    }),
  };

  useEffect(() => {
    const getUserDetails = async () => {
      const response = await StemvidaService.getUserDetail(id);
      setUser(response);
    };

    getUserDetails();
  }, [id]);

  const generatePassword = async () => {
    const response = await StemvidaService.generateTemporaryPassword(id);
    setTemporaryPassword(response);
  };

  const onSubmit = handleSubmit(async (data: DiscountWalletForm) => {
    const { newBalance } = await StemvidaService.decreaseWallet({
      amountToDecrease: Number(data.amountToDecrease),
      userId: id,
    });
    console.log(newBalance);
    setUser((u) => ({
      ...u!,
      wallet: newBalance,
    }));
  });

  return {
    user,
    temporaryPassword,
    generatePassword,
    FormFieldsRegisters,
    onSubmit,
  };
};

export default useUserDetails;
