import { Dispatch, useContext, useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import StemvidaService from "../../../../api/StemvidaService";
import OrderListsContext from "../../../../contexts/OrderListContext";
import { Product } from "../../../../interfaces/entities/Product";
import { CreateOrder } from "../../../../interfaces/payloads";
import { CreateOrderModalState } from "../CreateOrderModal";
import { NewOrderFormData } from "./interfaces";
import { User } from "../../../../interfaces/entities/User";

const atLeastOnePaymentMethodMessage = "Debe elegir al menos un metodo de pago";
const cantBuyMoreThanOnePackMessage = "No se puede facturar mas de un paquete";

interface useOrderFormProps {
  handleModalStateChange: (newState: CreateOrderModalState) => void;
  setReceipt: Dispatch<React.SetStateAction<number | undefined>>;
  userId?: string;
}

const useOrderForm = ({
  userId,
  handleModalStateChange,
  setReceipt,
}: useOrderFormProps) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [user, setUser] = useState<User>();
  const [createOrderError, setCreateOrderError] = useState<string>();
  const [validationError, setValidationError] = useState<string>();
  const { getOrders } = useContext(OrderListsContext);
  const { register, control, handleSubmit, watch, formState } =
    useForm<NewOrderFormData>({
      defaultValues: {
        userId,
        products: [{}],
      },
    });

  const { fields: productFields } = useFieldArray({
    control,
    name: "products",
  });

  const {
    fields: selectedPaymentMethods,
    insert,
    remove,
  } = useFieldArray({
    control,
    name: "paymentMethod",
  });

  const watchUser = watch("userId");
  const watchProducts = watch("products");
  const discount = watch("discount") || 0;
  const shipping = watch("shipping") || 0;
  const watchPaymentMethod = watch("paymentMethod") || [];

  const subTotal = watchProducts.reduce((a, b) => a + b.price * b.quantity, 0);
  const total = subTotal + shipping - discount;

  const thereIsMoreThanOnePack = useMemo(() => {
    const packs = watchProducts.filter(
      (product) => product.id === "2" || product.id === "3"
    );

    return packs.some((pack) => pack.quantity > 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watchProducts)]);

  useEffect(() => {
    const getProducts = async () => {
      const response = await StemvidaService.getProducts();
      setProducts(response);
    };

    const onSelectUser = async (userId: string) => {
      const response = await StemvidaService.getUserDetail(userId);
      setUser(response);
    };

    getProducts();
    if (watchUser) {
      onSelectUser(watchUser);
    }
  }, [watchUser]);

  useEffect(() => {
    if (
      watchPaymentMethod.length > 0 &&
      validationError === atLeastOnePaymentMethodMessage
    ) {
      setValidationError(undefined);
    }

    if (
      !thereIsMoreThanOnePack &&
      validationError === cantBuyMoreThanOnePackMessage
    ) {
      setValidationError(undefined);
    }
  }, [thereIsMoreThanOnePack, validationError, watchPaymentMethod.length]);

  const onSubmit = handleSubmit(async (data) => {
    if (data.paymentMethod.length === 0) {
      return setValidationError(atLeastOnePaymentMethodMessage);
    }

    if (thereIsMoreThanOnePack) {
      return setValidationError(cantBuyMoreThanOnePackMessage);
    }

    let paymentMethod = [];
    if (data.paymentMethod.length === 1) {
      paymentMethod = data.paymentMethod.map((item) => ({
        ...item,
        amount: total,
      }));
    } else {
      paymentMethod = data.paymentMethod;
    }

    const productsPayload = data.products.map((product) => {
      const selectedProduct = products.find(
        (p) => p.id === product.id
      ) as Product;

      return {
        ...product,
        name: selectedProduct.name,
        bottlesQuantity: selectedProduct.bottlesQuantity * product.quantity,
      };
    });

    const payload: CreateOrder = {
      ...data,
      paymentMethod,
      products: productsPayload,
    };
    try {
      const response = await StemvidaService.createOrder(payload);
      getOrders();
      setReceipt(response.orderId);
      handleModalStateChange("receipt");
    } catch (error: any) {
      if (error.response.data.code === 101) {
        setCreateOrderError(error.response.data.message);
      }
    }
  });

  return {
    total,
    watchPaymentMethod,
    watchProducts,
    products,
    productFields,
    selectedPaymentMethods,
    insert,
    remove,
    register,
    onSubmit,
    control,
    formState,
    validationError,
    user,
    createOrderError,
  };
};

export default useOrderForm;
