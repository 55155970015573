import React from "react";
import { CButton, CCard, CCardBody, CCol, CForm, CRow } from "@coreui/react";
import { useForm } from "react-hook-form";
import CommisionsTable from "../../components/CommisionsTable/CommisionsTable";
import UserSelector from "../../components/UserSelector/UserSelector";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";
import { User } from "../../interfaces/entities/User";
import StemvidaService from "../../api/StemvidaService";
import { useCallback } from "react";
import { MonthAndYear } from "../../interfaces/globals/DateRange";
import useSession from "../../hooks/useSession/useSession";
import { AccessLevel } from "../../interfaces/globals/session";
import Select from "../../components/Select/Select";
import { months, years } from "../../constants/monthsAndYears";

interface CommisionListFormData {
  userId: string;
  period: MonthAndYear;
}

const CommisionList: React.FC = () => {
  const today = new Date();
  const initialPeriod = {
    month: today.getMonth() + 1,
    year: today.getFullYear(),
  };
  const { control, watch } = useForm<CommisionListFormData>({
    defaultValues: {
      period: initialPeriod,
    },
  });

  const [period, setPeriod] = useState<MonthAndYear>(initialPeriod);

  const [user, setUser] = useState<User>();
  const { session } = useSession();
  const { t } = useTranslation();
  const userId =
    session?.accessLevel === AccessLevel.ADMIN ? watch("userId") : session?.id;

  const getUser = useCallback(async () => {
    if (userId) {
      const response = await StemvidaService.getUser(userId);
      setUser(response);
    }
  }, [userId]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <React.Fragment>
      <CRow className="mb-3">
        <CCol className="col-12 col-lg-8">
          <h1>{t("commission list")}</h1>
        </CCol>
        <CCol className="d-flex justify-content-end">
          <CButton size="lg" color="success">
            {t("print")}
          </CButton>
        </CCol>
      </CRow>
      <CCard>
        <CCardBody>
          <CRow className="mb-5">
            <CForm inline className="w-100">
              {session?.accessLevel === AccessLevel.ADMIN && (
                <CCol xs="12" md="5" className="mb-3 mb-lg-0">
                  <UserSelector
                    inline
                    label={t("user")}
                    control={control}
                    name="userId"
                  />
                </CCol>
              )}
              <CCol xs="12" md="4" className="mb-3 mb-lg-0">
                <Select
                  control={control}
                  inline
                  name="period.month"
                  label={t("month")}
                  hideSelectedOptions
                  options={months.map((month) => ({
                    label: t(month.label),
                    value: month.value,
                  }))}
                  handleOnChange={(v) => {
                    setPeriod((p) => ({
                      ...p,
                      month: Number(v),
                    }));
                  }}
                />
              </CCol>
              <CCol xs="12" md="3">
                <Select
                  control={control}
                  inline
                  name="period.year"
                  label={t("year")}
                  hideSelectedOptions
                  options={years}
                  handleOnChange={(v) => {
                    setPeriod((p) => ({
                      ...p,
                      year: Number(v),
                    }));
                  }}
                />
              </CCol>
            </CForm>
          </CRow>
          {userId && (
            <React.Fragment>
              <h2 className="font-weight-bold">{user?.name}</h2>
              <h5>
                {t("class")}: {user?.type}
              </h5>
              {period && <CommisionsTable userId={userId} period={period} />}
            </React.Fragment>
          )}
        </CCardBody>
      </CCard>
    </React.Fragment>
  );
};

export default CommisionList;
