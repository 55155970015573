import React from "react";
import CIcon from "@coreui/icons-react";
import {
  CCol,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CInvalidFeedback,
  CLabel,
} from "@coreui/react";
import { UseFormRegisterReturn } from "react-hook-form";
import debounce from "debounce";

export interface TextInputProps {
  label?: string;
  icon?: any;
  error?: string;
  inline?: boolean;
  register: UseFormRegisterReturn;
  type?: "text" | "password";
  autoComplete?: boolean;
  onChange?: (text: string) => void;
  placeholder?: string;
  debounceTime?: number;
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  icon,
  error,
  register,
  inline,
  type,
  autoComplete = false,
  onChange,
  placeholder,
  debounceTime = 0,
}) => {
  return (
    <CCol className={inline ? "d-flex flex-row mr-5" : ""}>
      <CLabel className={inline ? "mr-3" : ""}>{label}</CLabel>
      <CInputGroup>
        {icon && (
          <CInputGroupAppend>
            <CInputGroupText>
              <CIcon content={icon} />
            </CInputGroupText>
          </CInputGroupAppend>
        )}
        <input
          {...register}
          className={`form-control ${error && "is-invalid"}`}
          type={type}
          placeholder={placeholder}
          autoComplete={autoComplete ? "true" : "off"}
          onChange={debounce((e: any) => {
            register.onChange(e);
            onChange && onChange(e.target.value);
          }, debounceTime)}
        />
        <CInvalidFeedback>{error}</CInvalidFeedback>
      </CInputGroup>
    </CCol>
  );
};

export default TextInput;
