import React from "react";
import { cilUser, cilUserPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CCol, CRow } from "@coreui/react";
import { CreateOrderModalState } from "./CreateOrderModal";
import { useTranslation } from "react-i18next";

interface DistributorTypeSelectorProps {
  handleModalStateChange: (newState: CreateOrderModalState) => void;
}

const DistributorTypeSelector: React.FC<DistributorTypeSelectorProps> = ({
  handleModalStateChange,
}) => {
  const { t } = useTranslation();

  return (
    <CRow className="mb-3">
      <CCol>
        <CButton
          size="lg"
          color="success"
          variant="outline"
          onClick={() => handleModalStateChange("orderForm")}
          block
          className="py-5"
        >
          <CIcon content={cilUser} size="3xl" />
          <h3>{t("Existing dealer")}</h3>
        </CButton>
      </CCol>
      <CCol>
        <CButton
          size="lg"
          color="success"
          variant="outline"
          onClick={() => handleModalStateChange("newUser")}
          className="py-5"
          block
        >
          <CIcon content={cilUserPlus} size="3xl" />
          <h3>{t("New Distributor")}</h3>
        </CButton>
      </CCol>
    </CRow>
  );
};

export default DistributorTypeSelector;
