import React, { useState } from "react";
import { CModal, CModalBody } from "@coreui/react";
import NewUserForm from "./NewUserForm";
import UserTypeSelector from "./UserTypeSelector";
import StemvidaService from "../../../api/StemvidaService";
import { OptionTypeBase } from "react-select";
import OrderForm from "./OrderForm/OrderForm";
import { CreateUserFormData } from "./interfaces";
import Receipt from "../Receipt/Receipt";

interface CreateOrderModalProps {
  show: boolean;
  onClose: () => void;
}

export type CreateOrderModalState = "newUser" | "orderForm" | "receipt";

const CreateOrderModal: React.FC<CreateOrderModalProps> = (props) => {
  const { onClose, show } = props;
  const [modalState, setModalState] = useState<CreateOrderModalState>();
  const [user, setUser] = useState<OptionTypeBase>();
  const [receipt, setReceipt] = useState<number>();

  const handleSetUser = (newValue: OptionTypeBase) => {
    setUser(newValue);
  };

  const onSubmit = async (data: CreateUserFormData) => {
    const response = await StemvidaService.createUser({
      ...data,
      name: `${data.firstName} ${data.lastName}`,
    });

    setUser({
      value: response.id,
      label: `${response.id} - ${response.name}`,
    });

    setModalState("orderForm");
  };

  const handleModalStateChange = (newState: CreateOrderModalState) => {
    setModalState(newState);
    setUser(undefined);
  };

  return (
    <CModal
      show={show}
      onClose={onClose}
      onClosed={() => setModalState(undefined)}
      size="lg"
    >
      <CModalBody className="m-5">
        {!modalState && (
          <UserTypeSelector handleModalStateChange={handleModalStateChange} />
        )}
        {modalState === "newUser" && <NewUserForm onSubmit={onSubmit} />}
        {modalState === "orderForm" && (
          <OrderForm
            setReceipt={setReceipt}
            userOption={user}
            setUser={handleSetUser}
            handleModalStateChange={handleModalStateChange}
          />
        )}
        {modalState === "receipt" && receipt && <Receipt id={receipt} />}
      </CModalBody>
    </CModal>
  );
};

export default CreateOrderModal;
